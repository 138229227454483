
import { defineComponent, ref } from 'vue';
import { AxiosResponse } from 'axios';
import { useTransactionStore } from '@/stores/transaction/TransactionStore';
import { TransactionData, ExpectedResponse } from '@/entities/Transaction';
import moment from 'moment';

const columns = [
  {
    name: 'ID',
    align: 'left',
    label: 'Trx ID',
    field: 'ID',
    sortable: true,
  },
  {
    name: 'Type',
    align: 'left',
    label: 'Type',
    field: 'Type',
    sortable: true,
  },
  {
    name: 'Amount',
    align: 'left',
    label: 'Amount',
    field: 'Amount',
    sortable: true,
  },
  {
    name: 'Balance',
    align: 'left',
    label: 'Balance',
    field: 'Balance',
    sortable: true,
  },
  {
    name: 'Activity',
    align: 'left',
    label: 'Activity',
    field: 'Activity',
    sortable: true,
  },
    {
    name: 'WalletId',
    align: 'left',
    label: 'Wallet ID',
    field: 'WalletId',
    sortable: true,
  },
    {
    name: 'SttNo',
    align: 'left',
    label: 'Stt Number',
    field: 'SttNo',
    sortable: true,
  },
    {
    name: 'CreatedAt',
    align: 'left',
    label: 'Created At',
    field: 'CreatedAt',
    sortable: true,
  },
];
export default defineComponent({
  setup() {
    const transactionStore = useTransactionStore();

    const filter = '';
    const mode = 'list';
    const transaction = ref<TransactionData[]>([]);
    const pagination = ref({
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 1,
    });

    const getListTransaction = () => {
      const { rowsPerPage: limit, page } = pagination.value;
      transactionStore.getListTransaction({ page, limit }).then((res: AxiosResponse<ExpectedResponse>) => {
        if (res.data.data){
          transaction.value = res.data.data;
            for (const val of transaction.value) {
              val.CreatedAt = moment(val.CreatedAt).format('DD/MM/YYYY hh:mm')
              val.UpdatedAt = moment(val.UpdatedAt).format('DD/MM/YYYY hh:mm')
            }
          }
      });
    };

    const onPaginationUpdateNext = () => {
      if (transaction.value.length % pagination.value.rowsPerPage === 0) {
        pagination.value.page += 1;
        getListTransaction()
      }
    };

      const onPaginationUpdateBefore = () => {
        if (pagination.value.page > 1) {
            pagination.value.page -= 1;
            getListTransaction()
        }
    };

    getListTransaction();


    return {
      onPaginationUpdateBefore,
      onPaginationUpdateNext,
      pagination,
      transaction,
      columns,
    };
  },
});
