import { api } from '@/config/axios';
import { AxiosResponse, AxiosError } from 'axios';
import { ExpectedResponse, ListTransaction, TransactionData, TransactionMessage } from '@/entities/Transaction';
import { defineStore } from 'pinia';

interface State {
    message: TransactionMessage
    data: TransactionData[] | null;
    meta: ListTransaction | null;
}

export const useTransactionStore = defineStore('TransactionStore', {
    state: (): State => ({
        message: {
            en: '',
            id: ''
        },
        data: null,
        meta: null,
    }),
    actions: {
        getListTransaction(payload: ListTransaction): Promise<AxiosResponse<ExpectedResponse>> {
            return new Promise((resolve, reject) => {
                const url = `/v1/mutation?page=${payload.page}&limit=${payload.limit}`;
                api.get(url).then((res: AxiosResponse<ExpectedResponse>) => {
                    this.data = res.data.data;
                    this.meta = res.data.meta;
                    resolve(res);
                }).catch((err: AxiosError) => {
                    if (err.code !== '500') {
                        this.message.id = err.response?.data.message.id
                        reject(this.message.id)
                    }
                    reject(err)
                });
            });
        }
    },
    getters: {

    },
});