import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-pa-sm" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_page, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_table, {
                pagination: _ctx.pagination,
                "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagination) = $event)),
                title: "Transactions",
                rows: _ctx.transaction,
                columns: _ctx.columns,
                "rows-per-page-options": []
              }, {
                pagination: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    icon: "chevron_left",
                    color: "grey-8",
                    round: "",
                    dense: "",
                    flat: "",
                    onClick: _ctx.onPaginationUpdateBefore
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_q_btn, {
                    icon: "chevron_right",
                    color: "grey-8",
                    round: "",
                    dense: "",
                    flat: "",
                    onClick: _ctx.onPaginationUpdateNext
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["pagination", "rows", "columns"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}